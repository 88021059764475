.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-wrapper {
  height: 60vh;
  margin: 10px 50px;
  filter: drop-shadow(-1px 5px 3px #ccc);
}
.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}


.conic-gradient:after {
  display: block;
  border-radius: 9999px;
  content: '';
  width: 108%;
  height: 108%;
  position: absolute;
  z-index: -1;
  left: -4%;
  top: -4%;
  /* background-image: linear-gradient(
		45deg,
		#ffa95f 5%,
		#f99c4a 15%,
		#f47838 30%,
		#e75157 45%,
		#d92d7a 70%,
		#cc2a92 80%,
		#c32e92 95%
	); */
  /* background-image: conic-gradient(green, #fff, green, #fff, green); */
  animation: spin 5s linear infinite;
  border: "double 5px transparent";
  background-image: linear-gradient(to right,  rgb(130, 235, 26) ,  rgb(226, 223, 52));
  z-index: 2;
  background-origin: "border-box";
  background-clip: "content-box, border-box";
  /* box-shadow: 0 0 0 10px #ffffff inset; */
}

.conic-gradient2:after {
  display: block;
  border-radius: 9999px;
  content: '';
  width: 108%;
  height: 108%;
  /* position: absolute; */
  /* z-index: -1; */
  /* left: -4%; */
  /* top: -14%; */
  /* background-image: linear-gradient(
		45deg,
		#ffa95f 5%,
		#f99c4a 15%,
		#f47838 30%,
		#e75157 45%,
		#d92d7a 70%,
		#cc2a92 80%,
		#c32e92 95%
	); */
  /* background-image: conic-gradient(green, #fff, green, #fff, green); */
  animation: spin 5s linear infinite;
  border: "double 5px transparent";
  background-image: linear-gradient(to right,  rgb(130, 235, 26) ,  rgb(226, 223, 52));
  z-index: 2;
  background-origin: "border-box";
  background-clip: "content-box, border-box";
  /* box-shadow: 0 0 0 10px #ffffff inset; */
}
  @keyframes spin { 
  100% { 
    transform: rotate(360deg);
  }
}