
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .NoScrollBar::-webkit-scrollbar {
      display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .NoScrollBar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }