.Mappin {
    width: 50px;
    height: 50px;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -25px 0 0 -25px;
    animation-name: bounce;
    animation-duration: 1s;
    animation-fill-mode: both;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 2;
    background-color: #d12929;
}

.Mappin.open {
    background-color: #28a745;
}

.Mappin.closed {
    background-color: #d12929;
}

.Mappin.open:before,
.Mappin.closed:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    left: 5px;
    top: 45px;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0.5;
    animation: pulseEffect 2s infinite;
}

.Mappin.open:before {
    background-color: rgba(40, 167, 69, 0.5);
}

.Mappin.closed:before {
    background-color: rgba(209, 41, 41, 0.5);
}

@keyframes pulseEffect {
    0% { transform: translate(-50%, -50%) scale(1); opacity: 0.5; }
    50% { transform: translate(-50%, -50%) scale(1.5); opacity: 0.7; }
    100% { transform: translate(-50%, -50%) scale(1); opacity: 0.5; }
}

.Mappin img {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 50%;
}



.card {
    position: absolute;
    bottom: 50px;
    left: -60px;
    width: 240px;
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 10;
    border-radius: 8px;
    transition: opacity 0.3s ease-in-out;
}

.restaurant-image {
    width: 200px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 5px;
}

.card-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

p {
    font-size: 12px;
    margin: 0 0 10px;
    color: #333;
}

.details-button {
    display: inline-block;
    text-decoration: none;
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
}

.details-button:hover {
    background-color: #218838;
}

.ClusterMarker {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 255, 0.3);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.ClusterCircle {
    color: #fff;
    font-weight: bold;
}
